import store from '@/store/index'
export function handleTree (data, id, parentId, children) {
  const config = {
    id: id || 'id',
    parentId: parentId || 'parentId',
    childrenList: children || 'children'
  }

  const childrenListMap = {}
  const nodeIds = {}
  const tree = []

  for (const d of data) {
    const parentId = d[config.parentId]
    if (childrenListMap[parentId] == null) {
      childrenListMap[parentId] = []
    }
    nodeIds[d[config.id]] = d
    childrenListMap[parentId].push(d)
  }

  for (const d of data) {
    const parentId = d[config.parentId]
    if (nodeIds[parentId] == null) {
      tree.push(d)
    }
  }

  for (const t of tree) {
    adaptToChildrenList(t)
  }

  function adaptToChildrenList (o) {
    if (childrenListMap[o[config.id]] !== null) {
      o[config.childrenList] = childrenListMap[o[config.id]]
    }
    if (o[config.childrenList]) {
      for (const c of o[config.childrenList]) {
        adaptToChildrenList(c)
      }
    }
  }
  return tree
}

/**
*
* @param {*} time 格式化时间对象、时间戳、字符串
* @param {*} pattern  格式化模式
* @returns
*/
export function parseTime (time, pattern) {
  if (arguments.length === 0 || !time) {
    return null
  }
  const format = pattern || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
      time = parseInt(time)
    } else if (typeof time === 'string') {
      time = time.replace(new RegExp(/-/gm), '/').replace('T', ' ').replace(new RegExp(/\.[\d]{3}/gm), '')
    }
    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return time_str
}

export function getLastMonthsRange (number = 1) {
  // 获取当前日期
  const currentDate = new Date()

  // 获取number月前的日期
  const monthsAgo = new Date(currentDate)
  monthsAgo.setMonth(currentDate.getMonth() - number)

  // 确保开始时间为最近的周一
  const dayOfWeek = monthsAgo.getDay()
  const lastMonday = new Date(monthsAgo)
  lastMonday.setDate(lastMonday.getDate() - (dayOfWeek === 0 ? 6 : dayOfWeek - 1))

  // 确保结束时间为最近的周日
  const lastSunday = new Date(lastMonday)
  lastSunday.setDate(lastSunday.getDate() + 6 + number * 4 * 7)
  const pattern = '{y}-{m}-{d}'
  // 返回范围
  return [parseTime(lastMonday, pattern), parseTime(lastSunday, pattern)]
}

export function checkAuto (str) {
  const array = store.state.hasPermis
  return array.some(item => item == str)
}
