<template>
    <header class="nav_wrap">
        <div class="wrap">
            <div class="LOGO">
                <img src="@/assets/leadimg/hms.png" alt="" />
                <span>赫迈思科技</span>
            </div>
            <div class="search_box">
                <input placeholder="请输入文章标题" type="text" class="input" required="" v-model.trim="keyWords" />
                <span class="search_btn"><router-link tag="a"
                        :to="`/konwledge/cosmological?q=${keyWords}`" style="color:#fff;">搜索一下</router-link></span>
            </div>
            <div class="user">
                <div v-if="portrait">
                    <Avatar :src="baseURL + portrait" />
                </div>

                <Button type="primary" v-else>
                    <router-link to="/login">
                        <span style="color: #fff">登录&nbsp;|&nbsp;注册</span>
                    </router-link>
                </Button>
            </div>
        </div>
    </header>
</template>

<script>
import { baseURL } from '@/utils/axios'
export default {
  data () {
    return {
      baseURL,
      keyWords: ''
    }
  },
  props: {
    portrait: {
      type: String,
      default: ''
    }
  },
  methods: {

  }
}
</script>

<style lang="less" scope>
.nav_wrap {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    backdrop-filter: blur(10px);
    height: 52px;
    width: 100%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    background-color: #fff;

    .wrap {
        width: 1140px;
        margin: 0 auto;
        height: 100%;
        display: flex;
        // justify-content: space-between;
        position: relative;

        .LOGO {
            display: flex;
            align-items: center;
            height: 100%;
            position: absolute;
            top: 0;
            cursor: pointer;
            left: -150px;

            img {
                width: 30px;
                margin-right: 10px;
            }

            span {
                font-size: 20px;
                font-weight: 600;
                color: #333;
            }
        }

        .user {
            width: 100px;
            position: absolute;
            top: 0;
            right: -120px;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .search_box {
            width: 820px;
            display: flex;
            align-items: center;

            .search_btn {
                padding: 0.2rem 15px;
                line-height: 32px;
                letter-spacing: 0.5rem;
                font-size: 14px;
                color: #fff;
                background-color: #7a9cc6;
                border-radius: 0 1rem 1rem 0;
                opacity: 0;
                cursor: pointer;
            }

            .input {
                flex: 1;
                line-height: 28px;
                border: 2px solid transparent;
                border-bottom-color: #777;
                padding: 0.2rem 0;
                outline: none;
                background-color: transparent;
                color: #0d0c22;
                transition: 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            }

            .input::placeholder {
                color: rgba(119, 119, 119, 0.58);
                font-size: 14px;
                padding-left: 20px;
            }

            .input:focus::placeholder {
                opacity: 0;
                transition: opacity 0.3s;
            }
        }

        .search_box>.input:focus,
        .search_box>input:hover {
            outline: none;
            padding: 0.2rem 1rem;
            border-radius: 1rem 0 0 1rem;
            border-color: #7a9cc6;
        }

        .search_box>.input:focus+.search_btn,
        .search_box>input:hover+.search_btn {
            opacity: 1;
        }
    }
}
</style>
