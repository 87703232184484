import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import plugins from './plugins' // plugins
import directive from './directive/index.js'
import 'element-ui/lib/theme-chalk/index.css'
// 导入全局样式
import './assets/css/global.less'
// // 导入字体图标
// import './assets/fonts/iconfont.css'
// import './assets/fonts/iconfont'

// import 'font-awesome/css/font-awesome.css'
import axios, { download } from '@/utils/axios'

import methodsCom from '@/utils/methods'
// import dataV from '@jiaminghi/data-view'

// 引入iView
import iView from 'iview'
import 'iview/dist/styles/iview.css'

// ie
// import 'babel-polyfill'
// import promise from 'es6-promise'
// 挂载全局组件
import Pagination from '@/views/Pagination/index'
import QuerySelect from '@/views/QuerySelect' // 使用 CSS

// dataV
import { borderBox6, borderBox11, decoration1,decoration10,decoration9 } from '@jiaminghi/data-view'

Vue.use(borderBox6)
Vue.use(decoration1)
Vue.use(decoration10)
Vue.use(decoration9)
Vue.use(borderBox11)
Vue.use(iView)
// promise.polyfill()
// Vue.use(dataV)

Vue.use(plugins)
Vue.use(directive)
// 全局组件挂载
Vue.component('Pagination', Pagination)
Vue.component('QuerySelect', QuerySelect)

Vue.prototype.$http = axios
Vue.prototype.$methodsCom = methodsCom
Vue.prototype.$download = download

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
