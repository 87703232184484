import * as XLSX from 'xlsx'
import axios, { baseURL } from './axios'
import { Message, MessageBox } from 'element-ui'
import { parseTime } from './format'
import 'element-ui/lib/theme-chalk/index.css'
const confirm = MessageBox.confirm
const token = sessionStorage.getItem('token')
const _this = this

// 导出 excel
async function exportTable (blobURL, filename = '数据表格', options = {}) {
  const method = options.method || 'GET'
  const data = options.data || null

  try {
    const response = await axios({
      method,
      url: baseURL + blobURL,
      data,
      responseType: 'blob', // 重要：设置响应类型为blob
      headers: {
        Authorization: token // 添加TOKEN到请求头
      }
    })
    const blob = new Blob([response.data])
    const downloadElement = document.createElement('a')
    const href = window.URL.createObjectURL(blob) // 创建下载的链接
    downloadElement.href = href
    downloadElement.download = filename // 下载后文件名
    document.body.appendChild(downloadElement)
    downloadElement.click() // 点击下载
    document.body.removeChild(downloadElement) // 下载完成移除元素
    window.URL.revokeObjectURL(href) // 释放掉blob对象
    Message.success('导出成功')
  } catch (e) {
    console.error(e)
    Message.error('导出失败')
  }
}

// 自定义数据导出
function exportDataList (option) {
  confirm(`是否要导出${option.selectRow.length ? option.selectRow.length + '条' : '全部'}【“${option.title}”】数据?`, '数据列表导出', {
    confirmButtonText: '导出',
    cancelButtonText: '取消',
    type: 'success'
  }).then(() => {
    const datetime = parseTime(new Date(), '{y}{m}{d}{h}{i}{s}')
    const filename = option.title + datetime + '.xlsx'
    if (option.selectRow.length) {
      const headers = option.columns.reduce((acc, cur) => {
        acc.push(cur.label)
        return acc
      }, ['序号'])
      const data = option.selectRow.map((item, index) => {
        return option.columns.reduce((acc, cur) => {
          acc.push(item[cur.prop])
          return acc
        }, [index + 1])
      })
      data.unshift(headers)
      try {
        const workSheet = XLSX.utils.aoa_to_sheet(data)
        const bookNew = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(bookNew, workSheet, option.title) // 工作簿名称
        XLSX.writeFile(bookNew, filename)
        Message.success(filename + '导出成功!')
      } catch (e) {
        console.log(XLSX)

        console.error(e)
        Message.error('导出失败')
      }
      return
    }
    return exportTable(option.url, filename, { ...option })
  }).catch(() => {
    Message.info('已取消导出!')
  })
}

// 删除
function delet (type, url, ids, methods) {
  confirm('是否要删除?', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning'
  }).then(async () => {
    axios[type](url, { params: { id: ids } }).then(res => {
      Message.success('删除成功！')
      methods()
    }).catch((error) => {
      Message.error('删除失败！')
    })
  }).catch(() => {
    Message.info('已取消删除!')
  })
}

function dateTimeRange (day = 90, pattern = '{y}-{m}-{d}') {
  const end = new Date()
  const start = new Date()
  start.setTime(start.getTime() - 3600 * 1000 * 24 * day)
  end.setTime(end.getTime() + 3600 * 1000 * 24)
  return [parseTime(start, pattern), parseTime(end, pattern)]
}

export default {
  exportTable,
  exportDataList,
  delet,
  dateTimeRange
}
