<template>
  <div class="we">
    <div class="between left">
      <div class="box">
        <div class="logo">
          <img src="@/assets/leadimg/hms.png" /><span>赫迈思科技</span>
        </div>
        <p>专注于电子制造车间的数据挖掘与应用。自主研发HeMDC车间数据采集分析系统、以及基于SAAS云平台的HepEAM生产设备资产管理系统等专业数字化解决方案。</p>
      </div>
    </div>
    <div class="middle">
      <div class="title">联系方式</div>
      <div class="row">
        <span class="label">官方热线</span><span class="mh">:</span
        ><span class="value">13758255942</span>
      </div>
      <div class="row">
        <span class="label">客服电话</span><span class="mh">:</span
        ><span class="value">13758255942</span>
      </div>
      <div class="row">
        <span class="label">联系地址</span><span class="mh">:</span
        ><span class="value"
          >浙江省杭州市余杭区西溪悦城富力天贸广场27栋707</span
        >
      </div>
      <div class="row">
        <span class="label">服务时间</span><span class="mh">:</span
        ><span class="value">08:00-18:00（工作日）</span>
      </div>
      <div class="row">
        <span class="label">官网地址</span><span class="mh">:</span
        ><span class="value">https://www.he-mes.com/</span>
      </div>
    </div>
    <div class="between right">
      <div class="item">
        <img src="@/assets/barcode.jpg" />
        <span class="text">微信公众号</span>
      </div>
      <!-- <div class="item">
        <img src="@/assets/barcode.jpg" />
        <span class="text">官方企业微信</span>
      </div>
      <div class="item">
        <img src="@/assets/barcode.jpg" />
        <span class="text">官方微信群</span>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {}
  },
  created () {},
  methods: {}
}
</script>

<style lang="less" scope>
.we {
  height: 380px;
  padding-top: 50px;
  box-sizing: border-box;
  background: rgb(36, 41, 51);
  display: flex;
  //   align-items: center;
  .between {
    flex: 6;
    margin: 0px 40px;
  }
  .left {
    display: flex;
    flex-direction: row-reverse;
    .box {
      width: 270px;
      .logo {
        img {
          width: 60px;
          height: 60px;
          margin-right: 5px;
          vertical-align: middle;
        }
        span {
          font-size: 20px;
          font-weight: 600;
          color: #f3f3f3;
        }
      }
      p {
        font-size: 14px;
        line-height: 20px;
        margin-top: 30px;
      }
    }
  }
  .right {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    .item {
      width: 100px;
      margin: 0 20px 20px 0;
      img {
        width: 100px;
        height: 100px;
      }
      .text {
        font-size: 12px;
        line-height: 20px;
      }
    }
  }
  .middle {
    flex: 4;
    border-left: 1px solid #404040;
    border-right: 1px solid #404040;
    padding: 10px 30px;
    color: #fff;
    .title {
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 25px;
    }
    .row {
      font-size: 14px;
      line-height: 25px;
      margin-bottom: 15px;
      .mh {
        margin: 0 6px;
      }
    }
  }
}
</style>
